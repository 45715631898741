.app__About {
    min-height: 100vh;
    background: linear-gradient(135deg, #44aab8 0%, #287B8A 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.app__About-cardbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 1200px;
    width: 100%;
}

.app__About-cardbox-profile {
    flex: 0 0 auto;
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid white;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
}

.app__About-cardbox-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__About-cardbox-profile:hover {
    transform: scale(1.05);
}

.app__About-box {
    flex: 1 1 300px;
    max-width: 600px;
    padding: 20px;
    text-align: left;
}

.app__About-box h3 {
    font-family: 'Lexend', sans-serif;
    font-size: calc(1.5rem + 1vw); /* Ajuste dinâmico */
    font-weight: bold;
    color: #173463;
    margin: 10px 0;
}

.app__About-box h6 {
    font-family: 'Lexend', sans-serif;
    font-size: calc(1rem + 0.5vw); /* Ajuste dinâmico */
    font-weight: bold;
    color: #287B8A;
    margin: 20px 0 10px;
}

.app__About-box p {
    font-family: 'Lexend', sans-serif;
    font-size: calc(0.875rem + 0.25vw); /* Ajuste dinâmico */
    color: #666;
    text-align: justify;
    margin: 10px 0;
    line-height: 1.6;
}

.redes-sociais-about {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.socialmedia-logo {
    margin: 0 15px;
    color: #287B8A;
    transition: transform 0.3s, color 0.3s;
}

.socialmedia-logo:hover {
    transform: scale(1.2);
    color: #173463;
}

/* Media Queries para responsividade adicional */
@media (max-width: 992px) {
    .app__About-cardbox {
        padding: 20px;
    }

    .app__About-box h3 {
        font-size: calc(1.25rem + 1vw); /* Ajuste para telas menores */
    }

    .app__About-box h6 {
        font-size: calc(0.875rem + 0.5vw); /* Ajuste para telas menores */
    }

    .app__About-box p {
        font-size: calc(0.75rem + 0.25vw); /* Ajuste para telas menores */
    }
}

@media (max-width: 768px) {
    .app__About-cardbox {
        flex-direction: column;
        align-items: center;
    }

    .app__About-cardbox-profile {
        width: 18rem;
        height: 18rem;
    }

    .app__About-box h3 {
        font-size: calc(1.25rem + 1vw); /* Ajuste para telas menores */
    }

    .app__About-box h6 {
        font-size: calc(0.875rem + 0.5vw); /* Ajuste para telas menores */
    }

    .app__About-box p {
        font-size: calc(0.75rem + 0.25vw); /* Ajuste para telas menores */
    }
}

@media (max-width: 576px) {
    .app__About-cardbox-profile {
        width: 16rem;
        height: 16rem;
    }

    .app__About-box h3 {
        font-size: calc(1rem + 1vw); /* Ajuste para telas muito pequenas */
    }

    .app__About-box h6 {
        font-size: calc(0.75rem + 0.5vw); /* Ajuste para telas muito pequenas */
    }

    .app__About-box p {
        font-size: calc(0.625rem + 0.25vw); /* Ajuste para telas muito pequenas */
    }
}

@media (max-width: 400px) {
    .app__About-cardbox-profile {
        width: 14rem;
        height: 14rem;
    }

    .app__About-box h3 {
        font-size: calc(1rem + 1vw); /* Ajuste para telas muito pequenas */
    }

    .app__About-box h6 {
        font-size: calc(0.625rem + 0.5vw); /* Ajuste para telas muito pequenas */
    }

    .app__About-box p {
        font-size: calc(0.625rem + 0.25vw); /* Ajuste para telas muito pequenas */
    }
}
