html.no-scroll,
body.no-scroll {
  overflow: hidden;
}

.contentServicos {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.contentServicos.loaded {
  opacity: 1;
}