.woo-next-cart-wrapper .woo-next-cart-total-table {
    width: 80%;
    border: 2px solid #173463;
    box-shadow: 4px 4px #173463;
    border-radius: 5px;
    padding: 1.25em;
    margin: 2em auto;
}

.woo-next-cart-heading {
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    color: #44aab8;
    margin-bottom: 1.25em;
}

.woo-next-cart-total-table {
    width: 100%;
    border-collapse: collapse;
}

.woo-next-cart-header-container {
    font-size: 1em;
}

.woo-next-cart-header-container th {
    padding: 0.625em;
    text-align: center;
    border-bottom: 1px solid #173463;
}

tbody tr {
    border-bottom: 1px solid #173463;
}

tbody tr:hover {
    background-color: rgb(230, 230, 230);
}

tbody tr:last-child {
    border-bottom: none;
}

tbody td {
    padding: 0.625em;
    text-align: center;
    color: #173463;
}

tbody td:nth-child(3) {
    font-size: 0.75em;
    font-weight: 500;
    color: #44aab8;
}

tbody td:nth-child(4),
tbody td:nth-child(5),
tbody td:nth-child(6) {
    font-size: 0.75em;
    text-align: center;
}

/* Estilos para o contêiner total do carrinho */
.woo-next-cart-total-container {
    display: flex;
    justify-content: right;
    margin: 1.25em 0;
}

.woo-next-cart-total-container-col {
    width: 20%;
    margin-right: 10%;
    border-radius: 5px;
    border: 2px solid #173463;
    box-shadow: 4px 4px #173463;
}

.table-light {
    background-color: #fcfdfb;
}

.woo-next-cart-element-total {
    font-size: 1em;
    font-weight: 500;
    color: #173463;
    padding: 0.625em;
}

.woo-next-cart-element-amt {
    font-size: 1em;
    font-weight: 500;
    color: #44aab8;
    text-align: center;
}

/* Estilos para o botão de checkout */
.btn-checkout {
    background-color: #44aab8;
    color: white;
    font-size: 1em;
    font-weight: 600;
    padding: 0.625em 1.25em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin: 1.25em auto;
    display: block;
    box-shadow: 4px 4px #173463;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-checkout:hover {
    background-color: #173463;
    box-shadow: 4px 4px #44aab8;
    transform: translateY(-0.125em);
}

.woo-next-cart-checkout-text {
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
}

.link-checkout {
    text-decoration: none;
    width: 50%;
}

/* Responsividade */
@media (max-width: 768px) {
    .woo-next-cart-wrapper .woo-next-cart-total-table {
        width: 100%;
        padding: 0.625em;
    }

    .woo-next-cart-total-container {
        justify-content: center;
    }

    .woo-next-cart-total-container-col {
        width: 80%;
        margin-right: 0;
    }

    .btn-checkout {
        width: 100%;
    }

    .woo-next-cart-total-container-col {
        margin-top: 1.25em;
    }
}

@media (max-width: 480px) {
    .woo-next-cart-heading,
    .woo-next-cart-header-container th,
    .woo-next-cart-element-total,
    .woo-next-cart-element-amt {
        font-size: 0.875em;
    }

    .woo-next-cart-checkout-text {
        font-size: 0.625em;
    }

    .btn-checkout {
        font-size: 0.875em;
        padding: 0.5em 1em;
    }
}
