@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Estilos Gerais */
.bannerloja-container {
  position: relative;
  width: 100%;
  height: 80vh;
  background: url('../../../static/uploads/bannerLoja.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.bannerloja-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.bannerloja-container > * {
  position: relative;
  z-index: 2;
}

.bannerloja-content {
  padding: 2rem;
  border-radius: 10px;
}

.bannerloja-title {
  font-size: 4.5rem; /* Usar rem para tamanhos relativos */
  font-weight: 700;
  margin: 0;
  letter-spacing: 8px;
  color: #5ac8fa; /* Cor azul */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Sombra do texto */
}

.bannerloja-button {
  font-size: 1.5rem; /* Usar rem para tamanhos relativos */
  font-weight: 500;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  background-color: #357f8a;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: inline-block;
}

.bannerloja-button:hover {
  transform: scale(1.05);
  background-color: #2c6d73;
}

/* Animation Classes */
.hidden {
  opacity: 0;
  transform: translateY(50px);
}

.fadeInUp {
  opacity: 1;
  transform: translateY(0);
}

.fadeOutDown {
  opacity: 0;
  transform: translateY(50px);
}

/* Media Queries para Responsividade */
@media (max-width: 1200px) {
  .bannerloja-title {
    font-size: 3.5rem;
    letter-spacing: 6px;
  }

  .bannerloja-button {
    font-size: 1.25rem;
    padding: 0.6rem 1.2rem;
  }
}

@media (max-width: 992px) {
  .bannerloja-title {
    font-size: 3rem;
    letter-spacing: 4px;
  }

  .bannerloja-button {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .bannerloja-title {
    font-size: 2.5rem;
    letter-spacing: 2px;
  }

  .bannerloja-button {
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
  }

  .bannerloja-content {
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .bannerloja-title {
    font-size: 4rem;
    letter-spacing: 1px;
  }

  .bannerloja-button {
    font-size: 0.875rem;
    padding: 0.3rem 0.6rem;
  }

  .bannerloja-content {
    padding: 1rem;
  }
}

@media (max-width: 400px) {
  .bannerloja-title {
    font-size: 3rem;
    letter-spacing: 0.5px;
  }

  .bannerloja-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .bannerloja-content {
    padding: 0.5rem;
  }
}
