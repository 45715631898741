.app__Footer {
  padding: 40px 20px;
  font-family: 'Roboto', sans-serif;
}

.app__Footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.app__Footer-colorText {
  color: #44aab8;
  flex: 1 1 200px;
  margin-bottom: 20px;
}

.link-tiago-pedro {
  color: #44aab8; /* Cor padrão do link */
  text-decoration: none; /* Remove o sublinhado do link */
  transition: color 0.3s ease; /* Transição suave para a mudança de cor */
}

/* Adicionando uma margem à esquerda do link */
.link-tiago-pedro {
  color: #44aab8; /* Cor padrão do link */
  text-decoration: none; /* Remove o sublinhado do link */
  transition: color 0.3s ease; /* Transição suave para a mudança de cor */
  margin-left: 0.5rem; /* Ajuste o valor conforme necessário */
}

/* Efeito de hover */
.link-tiago-pedro:hover {
  color: #173463; /* Cor ao passar o mouse sobre o link */
}

.app__Footer-contacts,
.app__Footer-time,
.app__Footer-links {
  list-style: none;
  padding: 0;
}

.app__Footer-contacts li,
.app__Footer-time li,
.app__Footer-links li {
  display: flex;
  text-align: start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.app__Footer-contacts li span,
.app__Footer-time li span,
.app__Footer-links li span {
  color: #44aab8;
}

.app__Footer-time li span{
  display: flex;
  flex-direction: column;
}

.app__Footer-time li span span{
  margin-bottom: 25px;
}

.app__Footer-icon {
  margin-right: 10px;
  color: #44aab8;
}

.app__Footer-links li a {
  color: #44aab8;
  text-decoration: none;
  transition: color 0.3s ease;
}

.app__Footer-links li a:hover {
  color: #173463;
}

.app__Footer-links li a:focus {
  outline: 2px solid #44aab8;
  outline-offset: 4px;
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .app__Footer {
    padding: 20px 10px;
  }

  .app__Footer-info {
    flex-direction: column;
    align-items: center;
  }

  .app__Footer-colorText {
    margin-bottom: 30px;
    text-align: center;
  }

  .app__Footer-contacts,
  .app__Footer-time,
  .app__Footer-links {
    align-items: center;
  }

  .app__Footer-contacts li,
  .app__Footer-time li,
  .app__Footer-links li {
    justify-content: center;
  }

  .app__Footer-colorText p {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .app__Footer {
    padding: 20px 5px;
  }

  .app__Footer-info {
    flex-direction: column;
  }

  .app__Footer-colorText {
    width: 100%;
    margin-bottom: 20px;
  }

  .app__Footer-colorText p {
    font-size: 1rem;
  }

  .app__Footer-contacts li,
  .app__Footer-time li,
  .app__Footer-links li {
    justify-content: center;
    margin-bottom: 15px; /* Increased space between items */
  }

  .app__Footer-time li span {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .app__Footer-contacts,
  .app__Footer-time,
  .app__Footer-links {
    text-align: center;
  }
}

@media (max-width: 375px) {
  .app__Footer-colorText span {
    font-size: 0.9rem; /* Further reduce font size for very small screens */
  }
}

.text-center {
  text-align: center;
}

.text-center p {
  color: #44aab8;
}