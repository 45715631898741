.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background: url('../../../static/uploads/bg-breadcrumb.png') repeat;
  background-size: 20px 20px;
  position: relative;
}

.banner h1 {
  font-size: 3rem; /* Ajuste responsivo */
  font-weight: bold;
  color: #173463;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.breadcrumb {
  text-align: center;
  margin-top: 20px;
}

.breadcrumb_span {
  font-weight: bold;
  background-color: #fff;
  padding: 5px 2.5%;
  border: 1px solid #ccc;
  border-radius: 15px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  font-size: 1rem; /* Ajuste responsivo */
  transform: translateX(-50%);
  color: #173463;
}

/* Estilos para dispositivos pequenos (ex: celulares) */
@media (max-width: 600px) {
  .banner {
    height: 200px;
  }

  .banner h1 {
    font-size: 2rem;
    letter-spacing: 1px;
  }

  .breadcrumb_span {
    font-size: 0.8rem;
    padding: 4px 5%;
  }
}

/* Estilos para dispositivos médios (ex: tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .banner {
    height: 250px;
  }

  .banner h1 {
    font-size: 2.5rem;
    letter-spacing: 1.5px;
  }

  .breadcrumb_span {
    font-size: 0.9rem;
    padding: 5px 3%;
  }
}

/* Estilos para dispositivos grandes (ex: desktops) */
@media (min-width: 1025px) {
  .banner {
    height: 300px;
  }

  .banner h1 {
    font-size: 3rem;
  }

  .breadcrumb_span {
    font-size: 1rem;
    padding: 5px 2.5%;
  }
}
