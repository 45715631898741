.app_register-container {
    padding: 0;
    margin: 0;
}

.app_register-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    min-height: 100vh;
}

.app_register-right h2 {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    margin-top: 90px;
    color: #44aab8;
    text-align: center;
    width: 100%;
}

.app_register-right p {
    font-family: 'Lexend', sans-serif;
    color: #44aab8;
    text-align: center;
    font-size: small;
}

.app_register-right p a {
    text-decoration: none;
    color: #44aab8;
    font-weight: bold;
}

.app_register-right p a:hover {
    color: #173463;
}

.app_register-form {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.app_register-input-box {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 50px;
    border-bottom: 2px solid #44aab8;
    margin: 20px 0;
}

.app_register-input-box label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 1em;
    color: #44aab8;
    font-weight: 500;
    pointer-events: none;
    transition: .5s;
}

.app_register-input-box input:focus~label,
.app_register-input-box input:valid~label {
    top: -5px;
}

.app_register-input-box .icon {
    position: absolute;
    right: 8px;
    font-size: 1.2em;
    color: #44aab8;
    line-height: 57px;
}

.app_register-input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    color: #173463;
    font-weight: 600;
    padding: 0 35px 0 5px;
}

.app_register-button {
    display: flex;
    justify-content: center;
}

.app_register-btnlog {
    width: 70%;
    height: 30px;
    background-color: #44aab8;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    color: #fcfdfb;
    font-weight: 500;
    margin: 30px;
}

.app_register-btnlog:hover {
    background-color: #173463;
}

.app_register-logo {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 50px;
}

.app_register-logo img {
    width: 150px;
    height: auto;
}

@media (max-width: 576px) {
    .app_register-input-box input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        font-size: 1em;
        color: #173463;
        font-weight: 600;
        padding: 0 35px 0 5px;
    }
}

.bars {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.bars div {
    height: 6px;
    border-radius: 3px;
    transition: 0.4s;
    width: 0%;
}

.bars.weak div {
    background: #e24c71;
    width: 33.33%;
}

.bars.medium div {
    background: #f39845;
    width: 66.66%;
}

.bars.strong div {
    background: #57c558;
    width: 100%;
}

.strength {
    text-align: center;
    height: 30px;
    text-transform: capitalize;
    color: #868b94;
    margin-top: 5px;
    font-size: 0.9em;
}

/* Adicione ao seu arquivo CSS */
.my-swal-title {
    color: #dc3545;
    font-size: 1.5em;
    font-weight: bold;
}

.my-swal-text {
    color: #721c24;
}

.my-swal-footer a {
    color: #007bff;
    text-decoration: underline;
}

.my-swal-footer a:hover {
    color: #0056b3;
}
