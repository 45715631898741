/* Página da Loja */
.products_loja {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.products_loja-views {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Ajuste responsivo */
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Centraliza a grid horizontalmente */
  box-sizing: border-box;
}

/* Estilos para dispositivos pequenos (ex: celulares) */
@media (max-width: 600px) {
  .products_loja {
    padding: 0 10px;
  }

  .products_loja-views {
    grid-template-columns: 1fr; /* Uma coluna para celular */
    gap: 15px;
    padding: 10px;
  }
}

/* Estilos para dispositivos médios (ex: tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .products_loja {
    padding: 0 15px;
  }

  .products_loja-views {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Ajuste para tablets */
    gap: 20px;
    padding: 15px;
  }
}

/* Estilos para dispositivos grandes (ex: desktops) */
@media (min-width: 1025px) {
  .products_loja {
    padding: 0 20px;
  }

  .products_loja-views {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Manter o ajuste para desktops */
    gap: 20px;
    padding: 20px;
  }
}
