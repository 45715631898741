/* Estilos gerais para o menu */
.menu_blog-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menus-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: clamp(8px, 2vw, 16px) 5vw;
}

.menu_blog-nav-item,
.menu_loja-nav-item {
  text-decoration: none;
  color: #44aab8;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 15px;
  font-size: clamp(0.9rem, 1.5vw, 1.2rem); /* Tamanho fluido da fonte */
  font-weight: bold;
  padding: clamp(4px, 1vw, 8px) clamp(8px, 2vw, 12px);
  margin-right: clamp(6px, 1.5vw, 12px);
  margin-top: clamp(20px, 3vw, 30px);
  transition: all 0.3s ease;
}

.menu_blog-nav-item:hover,
.menu_loja-nav-item:hover {
  color: #173463;
}

.menu_loja-links {
  margin-left: 5%;
}

.menu_loja-search_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.menu_loja-input {
  --font-color: #173463;
  --font-color-sub: #666;
  --bg-color: #fff;
  --main-color: #173463;
  width: clamp(180px, 30%, 300px); /* Largura fluida */
  height: clamp(30px, 5vw, 40px); /* Altura fluida */
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-weight: 600;
  color: var(--font-color);
  padding: 5px 10px;
  outline: none;
  transition: all 0.3s ease;
}

.menu_loja-input::placeholder {
  color: var(--font-color-sub);
  opacity: 0.8;
}

.menu_loja-icon {
  width: clamp(30px, 6vw, 40px); /* Largura fluida */
  height: clamp(30px, 6vw, 40px); /* Altura fluida */
  border-radius: 5px;
  border: 2px solid #173463;
  box-shadow: 4px 4px #173463;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: clamp(8px, 2vw, 12px);
  transition: all 0.3s ease;
}

.menu_loja-search_icon {
  fill: #173463;
  width: clamp(16px, 3vw, 20px); /* Tamanho fluido */
  height: clamp(16px, 3vw, 20px); /* Tamanho fluido */
  transition: fill 0.3s ease;
}

.menu_loja-icon:hover,
.menu_loja-input:focus {
  border: 2px solid #44aab8;
  box-shadow: 4px 4px #44aab8;
}

.menu_loja-icon:hover .menu_loja-search_icon {
  fill: #44aab8;
}

/* Media queries para responsividade */

/* Estilos para dispositivos pequenos (ex: celulares) */
@media (max-width: 600px) {
  .menus-nav {
    flex-direction: column;
    padding: clamp(6px, 3vw, 12px) 3vw;
    gap: 10px;
  }

  .menu_loja-links {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .menu_loja-search_bar {
    margin: 10px 0;
    width: 100%;
    justify-content: space-between;
  }

  .menu_blog-nav-item,
  .menu_loja-nav-item {
    font-size: clamp(0.6rem, 2.5vw, 0.6rem);
    padding: 5px 4%;
    transform: none; /* Remove o translateX para centralizar */
  }
}

/* Estilos para dispositivos médios (ex: tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .menus-nav {
    padding: clamp(8px, 2vw, 16px) 4vw;
  }

  .menu_loja-search_bar {
    margin: 10px 0;
  }

  .menu_blog-nav-item,
  .menu_loja-nav-item {
    font-size: clamp(0.6rem, 2.5vw, 0.6rem);
    padding: 5px 3%;
  }
}

/* Estilos para dispositivos grandes (ex: desktops) */
@media (min-width: 1025px) {
  .menus-nav {
    padding: 10px 5vw;
  }

  .menu_blog-nav-item,
  .menu_loja-nav-item {
    font-size: 0.8rem;
  }
}
