/* Container geral */
.blog_container {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 0 1rem;
}

/* Blog posts container */
.blogs_posts {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* Blog card */
.post_blog {
  flex: 1 1 calc(33.333% - 1rem);
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.post_blog:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Blog card image */
.app_blog-other-card-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Blog card text */
.app_blog-other-card-text {
  padding: 1rem;
}

/* Category Tags */
.app_blog-other-card-text-category {
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 0.875rem; /* 14px */
  color: #173463; /* Ajuste para a cor dos tags */
}

/* Title */
.app_blog-other-card-text-title {
  font-size: 1.125rem; /* 18px */
  margin: 0.5rem 0;
  color: #173463; /* Cor do título */
}

/* Date */
.app_blog-other-card-text-date {
  font-size: 0.875rem; /* 14px */
  color: #44aab8;
}

/* Excerpt */
.app_blog-other-card-text-except {
  font-size: 0.875rem; /* 14px */
  margin: 1rem 0;
  color: #173463;
}

/* Button */
.app_blog-other-card-text-links {
  margin-top: 1rem;
}

.app_blog-other-card-text-links-link {
  color: #ffffff; /* Cor do texto do botão */
  background-color: #44aab8; /* Cor de fundo do botão */
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem; /* 14px */
  padding: 0.75rem 1.25rem; /* Tamanho e preenchimento do botão */
  border-radius: 4px;
  display: inline-block;
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 2px solid transparent; /* Borda invisível para efeito de foco */
}

.app_blog-other-card-text-links-link:hover {
  background-color: #173463; /* Cor do botão ao passar o mouse */
  color: #44aab8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ao passar o mouse */
}

.app_blog-other-card-text-links-link:focus {
  border-color: #173463; /* Cor da borda ao focar */
  outline: none; /* Remove o contorno padrão do navegador */
}

/* Pagination */
.app_blog-pageNavigation {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.app_blog-pageNavigation-item {
  margin: 0 0.5rem;
}

.app_blog-pageNavigation-item-link {
  text-decoration: none;
  color: #173463;
  font-size: 0.875rem; /* 14px */
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.app_blog-pageNavigation-item-link:hover {
  background-color: #f5f5f5; /* Cor de fundo ao passar o mouse */
  color: #173463;
}

/* Active page */
.app_blog-pageNavigation-item.active .app_blog-pageNavigation-item-link {
  background-color: #173463;
  color: #ffffff;
}

/* Alert */
.alert-blogs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 8px;
  color: #856404;
  margin: 1rem 0;
}

.alert-blogs p {
  margin: 0;
}

.alert-blogs svg {
  margin-right: 0.5rem;
}

/* Spinner */
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

/* Media Queries */

/* Para dispositivos grandes (ex: desktops) */
@media (min-width: 1025px) {
  .post_blog {
    flex: 1 1 calc(33.333% - 1rem);
  }
}

/* Para dispositivos médios (ex: tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .post_blog {
    flex: 1 1 calc(50% - 1rem);
  }

  .app_blog-other-card-text-category {
    font-size: 0.825rem; /* 13px */
  }

  .app_blog-other-card-text-title {
    font-size: 1.075rem; /* 17px */
  }

  .app_blog-other-card-text-date,
  .app_blog-other-card-text-except,
  .app_blog-other-card-text-links-link {
    font-size: 0.85rem; /* 13.6px */
  }
}

/* Para dispositivos pequenos (ex: celulares) */
@media (max-width: 600px) {
  .post_blog {
    flex: 1 1 100%;
  }

  .app_blog-other-card-text-category {
    font-size: 0.8rem; /* 12.8px */
  }

  .app_blog-other-card-text-title {
    font-size: 1rem; /* 16px */
  }

  .app_blog-other-card-text-date,
  .app_blog-other-card-text-except,
  .app_blog-other-card-text-links-link {
    font-size: 0.8rem; /* 12.8px */
  }
}

@media (max-width: 576px) {
  .app_blog-other-card-text {
    padding: 0.5rem;
  }

  .app_blog-other-card-text-category {
    font-size: 0.75rem; /* 12px */
  }

  .app_blog-other-card-text-title {
    font-size: 0.875rem; /* 14px */
  }

  .app_blog-other-card-text-date,
  .app_blog-other-card-text-except,
  .app_blog-other-card-text-links-link {
    font-size: 0.75rem; /* 12px */
  }
}
