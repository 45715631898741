/* Estilos Globais */
html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

html.no-scroll, body.no-scroll {
  overflow: hidden;
}

/* Contêiner Principal */
.app_ClinicaDetail-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fade-in 0.5s ease forwards;
}

/* Título Principal */
.app_ClinicaDetail-container-title {
  color: #173463;
  font-size: 2rem; /* Ajustado para responsividade */
  font-weight: 600;
  margin-bottom: 1.25rem; /* Ajustado para responsividade */
  text-align: center;
}

/* Conteúdo */
.app_ClinicaDetail-container-content {
  color: #333;
  line-height: 1.6;
  margin: 1.25rem 0; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content p {
  margin-bottom: 1rem; /* Ajustado para responsividade */
  font-size: 1rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content a {
  color: #44aab8;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content a:hover {
  color: #0056b3;
}

.app_ClinicaDetail-container-content img,
.app_ClinicaDetail-container-content video {
  max-width: 100%;
  margin-bottom: 0.75rem; /* Ajustado para responsividade */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app_ClinicaDetail-container-content img:hover,
.app_ClinicaDetail-container-content video:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.app_ClinicaDetail-container-content h1,
.app_ClinicaDetail-container-content h2,
.app_ClinicaDetail-container-content h3 {
  color: #44aab8;
  margin-bottom: 1.25rem; /* Ajustado para responsividade */
}

/* Tamanhos das fontes para os títulos */
.app_ClinicaDetail-container-content h1 {
  font-size: 2rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content h2 {
  font-size: 1.5rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content h3 {
  font-size: 1.25rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container blockquote {
  font-style: italic;
  border-left: 5px solid #44aab8;
  padding-left: 1rem; /* Ajustado para responsividade */
  margin: 1.25rem 0; /* Ajustado para responsividade */
  font-size: 1rem; /* Ajustado para responsividade */
}

/* Listas */
.app_ClinicaDetail-container-content ul,
.app_ClinicaDetail-container-content ol {
  margin: 1.25rem 0; /* Ajustado para responsividade */
  padding-left: 1.5rem; /* Ajustado para responsividade */
}

.app_ClinicaDetail-container-content li {
  margin-bottom: 0.75rem; /* Ajustado para responsividade */
  font-size: 1rem; /* Ajustado para responsividade */
}

/* Animação de Fade-In */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade */
@media (max-width: 1200px) {
  .app_ClinicaDetail-container-title {
    font-size: 1.75rem; /* Ajustado para dispositivos de tela média */
  }

  .app_ClinicaDetail-container-content p,
  .app_ClinicaDetail-container-content a,
  .app_ClinicaDetail-container-content li {
    font-size: 0.875rem; /* Ajustado para dispositivos de tela média */
  }

  .app_ClinicaDetail-container-content h1 {
    font-size: 1.75rem; /* Ajustado para dispositivos de tela média */
  }

  .app_ClinicaDetail-container-content h2 {
    font-size: 1.25rem; /* Ajustado para dispositivos de tela média */
  }

  .app_ClinicaDetail-container-content h3 {
    font-size: 1rem; /* Ajustado para dispositivos de tela média */
  }
}

@media (max-width: 992px) {
  .app_ClinicaDetail-container-title {
    font-size: 1.5rem; /* Ajustado para tablets */
  }

  .app_ClinicaDetail-container-content p,
  .app_ClinicaDetail-container-content a,
  .app_ClinicaDetail-container-content li {
    font-size: 0.9rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h1 {
    font-size: 1.45rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h2 {
    font-size: 1.2rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h3 {
    font-size: 1.0rem; /* Ajustado para dispositivos pequenos */
  }
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 1.25rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-title {
    font-size: 1.25rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content p,
  .app_ClinicaDetail-container-content a,
  .app_ClinicaDetail-container-content li {
    font-size: 0.9rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h1 {
    font-size: 1.45rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h2 {
    font-size: 1.2rem; /* Ajustado para dispositivos pequenos */
  }

  .app_ClinicaDetail-container-content h3 {
    font-size: 1.0rem; /* Ajustado para dispositivos pequenos */
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    max-width: 90%;
    margin-bottom: 1.25rem; /* Ajustado para dispositivos pequenos */
  }
}

@media (max-width: 480px) {
  .app_ClinicaDetail-container-title {
    font-size: 1rem; /* Ajustado para smartphones */
  }

  .app_ClinicaDetail-container-content p,
  .app_ClinicaDetail-container-content a,
  .app_ClinicaDetail-container-content li {
    font-size: 0.8rem; /* Ajustado para smartphones */
  }

  .app_ClinicaDetail-container-content h1 {
    font-size: 1.2rem; /* Ajustado para smartphones */
  }

  .app_ClinicaDetail-container-content h2 {
    font-size: 0.9rem; /* Ajustado para smartphones */
  }

  .app_ClinicaDetail-container-content h3 {
    font-size: 0.8rem; /* Ajustado para smartphones */
  }
}
