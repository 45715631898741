/* Estilos gerais */
.app__slideShow {
    position: relative;
    overflow: hidden;
    margin-bottom: 6px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__slideShow video {
    min-width: 100%;
    min-height: auto;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.app__slideShow-text {
    position: relative; /* Altera a posição para relative */
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem; /* Tamanho de fonte padrão */
    color: #173463;
    text-shadow: 2px 2px 5px #44aab8;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 90%; /* Limita a largura do texto para telas menores */
}

.app__slideShow-text span {
    color: #173463;
}

/* Media Queries para responsividade */
@media (max-width: 768px) { /* Tablets e telas menores */
    .app__slideShow-text {
        font-size: 1.25rem; /* Reduz o tamanho da fonte em tablets */
        padding: 8px 16px;
    }
}

@media (max-width: 480px) { /* Dispositivos móveis */
    .app__slideShow-text {
        font-size: 1rem; /* Reduz ainda mais o tamanho da fonte em dispositivos móveis */
        padding: 6px 12px;
    }

    .app__slideShow video {
        min-height: 100vh;
        min-width: 100%;
    }
}

.icon-pos {
    margin-right: 10px;
}
