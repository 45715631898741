/* Estilos Gerais */
.container-clinica-area {
  display: flex;
  flex-wrap: nowrap; /* Mantém os itens na mesma linha */
  overflow-x: hidden; /* Esconde a barra de rolagem horizontal */
}

.panel {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh; /* Altura total da janela */
  padding: 0 5vw; /* Padding lateral */
  box-sizing: border-box;
  flex: 0 0 100vw; /* Garante que cada painel ocupe 100vw */
}

.panel img {
  width: 40%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.panel .panel-image,
.panel .information .information-title,
.panel .information .information-excerpt,
.panel .information .information-link {
  visibility: hidden;
}

.panel .panel-image {
  order: 2;
  margin-left: 2vw;
}

.panel .information {
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8vh 2vw;
}

.panel .information .information-title {
  margin-bottom: 3vh;
  font-family: 'Lexend', sans-serif;
  font-size: 2rem;
  font-weight: 800;
}

.panel .information .information-excerpt {
  margin-bottom: 1vh;
  margin-left: 1.5vw;
  margin-right: 6vw;
  font-size: 1rem;
}

.panel .information .information-link {
  display: flex;
  justify-content: end;
  margin-top: 2vh;
}

.panel .information .information-link .panel-link {
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel:nth-child(even) {
  background-color: #44aab8; /* Azul claro */
  color: #fcfbfd;
}

.panel:nth-child(odd) {
  background-color: #fcfbfd; /* Branco */
  color: #44aab8;
}

.panel:nth-child(even) .information .information-link .panel-link {
  color: #fcfbfd;
}

.panel:nth-child(even) .information .information-link .panel-link:hover {
  background-color: #fcfbfd;
  color: #44aab8;
}

.panel:nth-child(odd) .information .information-link .panel-link {
  color: #44aab8;
  background-color: #fcfbfd;
}

.panel:nth-child(odd) .information .information-link .panel-link:hover {
  color: #fcfbfd;
  background-color: #44aab8;
}

.panel:nth-child(odd) .information .information-excerpt {
  color: #173463;
}

/* Media Queries para Responsividade */
@media (max-width: 1200px) {
  .panel img {
    width: 30%;
  }

  .panel .information .information-title {
    font-size: 1.75rem;
  }

  .panel .information .information-excerpt {
    font-size: 0.9375rem;
  }

  .panel .information .information-link .panel-link {
    padding: 0.5rem;
  }
}

@media (max-width: 992px) {
  .panel {
    flex-direction: column;
    padding: 5vw 2vw;
  }

  .panel img {
    width: 40%;
    margin: 2vh 0;
  }

  .panel .information {
    margin: 5vh 0;
  }

  .panel .information .information-title {
    font-size: 1.5rem;
  }

  .panel .information .information-excerpt {
    font-size: 0.875rem;
  }

  .panel .information .information-link .panel-link {
    padding: 0.4rem;
  }
}

@media (max-width: 768px) {
  .panel img {
    width: 40%;
    margin: 1vh 0;
  }

  .panel .information {
    margin: 3vh 0;
  }

  .panel .information .information-title {
    font-size: 1.25rem;
  }

  .panel .information .information-excerpt {
    font-size: 0.8125rem;
  }

  .panel .information .information-link .panel-link {
    padding: 0.35rem;
  }
}

@media (max-width: 576px) {
  .panel img {
    width: 80%;
    margin: 1vh 0;
  }

  .panel .information .information-title {
    font-size: 1rem;
  }

  .panel .information .information-excerpt {
    font-size: 0.75rem;
  }

  .panel .information .information-link .panel-link {
    padding: 0.3rem;
  }
}

@media (max-width: 400px) {
  .panel img {
    width: 90%;
    margin: 1vh 0;
  }

  .panel .information .information-title {
    font-size: 0.875rem;
  }

  .panel .information .information-excerpt {
    font-size: 0.625rem;
  }

  .panel .information .information-link .panel-link {
    padding: 0.25rem;
  }
}
