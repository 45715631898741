/* Estilo para o container da página */
.page.carousel-1-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 0 8vw;
  color: #173463;
}

/* Estilo para o swiper */
.page .swiper {
  width: 100vh;
  max-width: 800px;
  height: 80vh;
  border-radius: 15px;
  margin: 50px;
  overflow: hidden;
}

/* Estilo para cada slide do swiper */
.page .swiper-slide {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Estilo para os títulos dos slides */
.page .swiper-slide h2 {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  font-size: 1.0rem;
  line-height: 1.5;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}

/* Estilo para os links dentro dos slides */
.page .swiper-slide a {
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 1.5rem;
  margin: 0 auto;
  padding: 10px 26px;
  font-size: 0.9rem;
  transition: 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.6);
  color: #173463;
}

/* Estilo para os links no hover */
.page .swiper-slide a:hover {
  background-color: rgba(255, 255, 255, 1);
}

/* Estilo para os elementos dentro dos slides */
.page .swiper-slide div {
  position: absolute;
  z-index: 1;
  inset: 0;
  transition: opacity 0.25s;
  opacity: 0;
  align-self: stretch;
  padding-bottom: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 70px;
}

/* Estilo para o slide ativo */
.page .swiper-slide-active div {
  opacity: 1;
}

/* Animação de entrada dos elementos */
.page .swiper-slide > div > div {
  transform: translateY(100px);
  transition: transform 0.3s;
}

.page .swiper-slide-active > div > div {
  transform: translateY(0);
}

/* Estilo para os pontos de paginação */
.page .swiper-pagination-bullet,
.page .swiper-pagination-bullet-active {
  background: #44aab8 !important;
}

.page .swiper-pagination {
  bottom: 10px !important;
  transform: scale(1.3);
}

/* Tela pequena (ex: celulares) */
@media (max-width: 600px) {
  .page.carousel-1-page {
    flex-direction: column;
    padding: 0 5vw;
  }

  .page .swiper {
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    margin: 20px;
  }

  .page .swiper-slide h2 {
    font-size: 0.8rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }

  .page .swiper-slide a {
    font-size: 0.6rem;
    padding: 8px 20px;
  }

  .page .swiper-pagination {
    bottom: 5px !important;
    transform: scale(1);
  }
}

/* Tela média (ex: tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .page.carousel-1-page {
    flex-direction: column;
    padding: 0 6vw;
  }

  .page .swiper {
    width: 80vw;
    max-width: 90%;
    height: 70vh;
    margin: 30px;
  }

  .page .swiper-slide h2 {
    font-size: 1.0rem;
    line-height: 1.4;
    margin-bottom: 0.75rem;
  }

  .page .swiper-slide a {
    font-size: 0.85rem;
    padding: 9px 24px;
  }

  .page .swiper-pagination {
    bottom: 7px !important;
    transform: scale(1.1);
  }
}

/* Tela grande (ex: desktops grandes) */
@media (min-width: 1025px) {
  .page.carousel-1-page {
    padding: 0 10vw;
  }

  .page .swiper {
    width: 70vw;
    max-width: 900px;
    height: 80vh;
    margin: 50px;
  }

  .page .swiper-slide h2 {
    font-size: 1.0rem;
    line-height: 1.5;
    margin-bottom: 0.625rem;
  }

  .page .swiper-slide a {
    font-size: 0.9rem;
    padding: 10px 26px;
  }

  .page .swiper-pagination {
    bottom: 10px !important;
    transform: scale(1.3);
  }
}
