.woo-next-cart-wrap {
    display: flex;
    align-items: center;
    color: #44aab8;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.woo-next-cart-wrap:hover {
    color: #173463;
}

.woo-next-cart-wrap:hover .woo-next-cart-count {
    background-color: #173463;
}

.woo-next-cart-wrap:hover .woo-next-cart-price {
    color: #173463;
}

.woo-next-cart-price {
    margin-right: 0.3rem; /* Margem mais flexível */
    font-size: 0.9rem; /* Tamanho de fonte flexível */
    font-weight: 600;
    transition: color 0.3s ease-in-out;
    font-family: 'Inter', sans-serif; /* Tipografia moderna */
}

.woo-next-cart-icon-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 1.5rem; /* Tamanho flexível do ícone */
    height: 1.5rem; /* Tamanho flexível do ícone */
}

.woo-next-cart-count {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: #44aab8;
    color: #fff;
    border-radius: 50%;
    padding: 0.125rem 0.25rem; /* Padding flexível */
    font-size: 0.6rem; /* Tamanho de fonte flexível */
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav_link_button-icon {
    font-size: 1.5rem; /* Tamanho flexível do ícone */
    color: inherit; /* Herda a cor do texto */
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.woo-next-cart-wrap:hover .nav_link_button-icon {
    transform: translateY(-3px);
    color: #173463;
}
