@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Estrutura geral da página */
.productdetail-main {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #eef1f5; /* Cor de fundo suave */
  font-family: 'Roboto', sans-serif; /* Fonte moderna */
}

/* Container principal do produto */
.productdetail {
  margin: 20px;
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #d1d9e6;
  overflow: hidden;
}

/* Layout flexível para itens do produto */
.productdetail-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 768px) {
  .productdetail-item {
    flex-direction: row;
  }
}

/* Containers principais */
.primary-left-container, .primary-right-container {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .primary-left-container {
    flex: 2;
    margin-right: 30px;
  }

  .primary-right-container {
    flex: 1;
    margin-left: 30px;
  }
}

/* Container superior interno */
.inner-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* Suporte do produto */
.produto-support {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #173463;
}

/* Título do produto */
.produto-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #173463;
}

/* Container de preço e compra */
.produto-price-buying {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

/* Botões de ação */
.produto-price-buying button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.btn-download {
  background-color: #44aab8;
  color: #fcfdfb;
}

.btn-download:hover {
  background-color: #173463;
}

/* Ícone dos botões */
.produto-price-buying svg {
  margin-right: 8px;
}

/* Sinopse do produto */
.produto-sinopse {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Container de imagem */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-container-img {
  width: 100%;
  max-width: 480px;
  height: auto;
}

.image-container-img img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Classificação do produto */
.produto-classification {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estrelas e avaliações */
.produto-stars-reviews {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: #ffb400;
}

/* Número de avaliações */
.produto-n-reviews {
  font-size: 1.1rem;
  color: #173463;
}
