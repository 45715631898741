/* Container principal do NavBar */
.app__navBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(0.5rem, 2vw, 1rem);
}

/* Logotipo */
.app__navBar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__navBar-logo img {
  width: clamp(100px, 15vw, 150px); /* Largura fluida para o logotipo */
  margin: clamp(5px, 1.5vw, 10px);
}

/* Links de navegação */
.app__navBar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}

.app__navBar-links li {
  display: flex;
  align-items: center;
  margin: 0 clamp(5px, 1.5vw, 10px); /* Margens laterais fluídas */
}

.app__navBar-links li a {
  margin: 0 clamp(5px, 1.5vw, 10px);
  cursor: pointer;
  color: #44aab8;
  font-size: clamp(12px, 2vw, 16px); /* Fontes adaptáveis */
  font-weight: 600;
  font-family: 'Inter', sans-serif; /* Tipografia moderna */
  transition: color 0.1s ease;
}

.app__navBar-links li a:hover,
.app__navBar-links li a:hover .color-icon {
  color: #173463;
}

.app__navBar-links li a span {
  margin-left: clamp(2px, 1vw, 5px); /* Espaço fluido para ícones e texto */
}

/* Container dos botões de navegação */
.nav-button-container {
  display: flex; /* Alinha os itens horizontalmente */
  align-items: center;
  justify-content: space-around;
  border: 2px solid #44aab8;
  margin: clamp(10px, 2.5vw, 15px);
  padding: clamp(3px, 1vw, 5px);
  border-radius: 10%;
}

/* Estilo dos botões de navegação */
.nav_link-button {
  outline: 0 !important;
  border: 0 !important;
  width: clamp(15px, 4vw, 20px);
  height: clamp(15px, 4vw, 20px);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #44aab8;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.nav_link-button:hover {
  transform: translateY(-3px);
}

/* Ícones dos botões de navegação */
.nav_link_button-icon {
  font-size: clamp(16px, 4vw, 20px); /* Tamanho adaptável dos ícones */
}

/* Tipografia recomendada */
body {
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
