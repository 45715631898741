/* Container principal dos comentários */
.comments-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff; /* Fundo branco */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

/* Título da seção de comentários */
.comments-section h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #173463; /* Cor viva para o título */
  font-weight: 700; /* Fonte mais forte */
}

/* Lista de comentários */
.comments-list {
  margin-bottom: 20px;
}

.comment-content{
  color: #44aab8;
}

/* Comentário individual */
.comment {
  border-bottom: 1px solid #e0e0e0; /* Borda sutil */
  padding: 15px 0;
  display: flex;
  flex-direction: column;
}

.comment:last-child {
  border-bottom: none; /* Remove a borda do último comentário */
}

.comment strong {
  color: #173463; /* Cor para o nome do revisor */
  font-weight: 600; /* Fonte mais leve que o título */
}

/* Formulário de comentário */
.comment-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espaçamento entre os elementos */
}

.comment-form h4{
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #173463; /* Cor viva para o título */
  font-weight: 700; /* Fonte mais forte */
}

/* Área de texto para comentários */
.comment-form textarea {
  width: 100%;
  height: 120px; /* Altura ajustada para textarea */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 1rem;
  resize: vertical;
  box-sizing: border-box;
  font-family: Arial, sans-serif; /* Fonte padrão */
}

/* Botão de envio do comentário */
.comment-form button {
  align-self: flex-end;
  padding: 12px 24px; /* Maior área clicável */
  border: none;
  border-radius: 5px; /* Bordas arredondadas */
  background-color: #44aab8; /* Cor do botão moderna */
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600; /* Fonte mais leve que o título */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Efeito hover para o botão */
.comment-form button:hover {
  background-color: #173463; /* Cor de hover mais escura */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra leve ao passar o mouse */
}

/* Responsividade */
@media (max-width: 768px) {
  .comment-form {
    gap: 10px; /* Menor espaçamento em telas menores */
  }

  .comment-form textarea {
    height: 100px; /* Ajusta a altura do textarea em telas menores */
  }

  .comment-form button {
    width: 100%; /* Botão ocupa toda a largura em telas menores */
  }
}
