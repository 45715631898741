.products_loja-card {
    --font-color: #44aab8;
    --font-color-sub: #173463;
    --bg-color: #fcfdfb;
    --main-color: #173463;
    --main-focus: #44aab8;
    width: 80%; /* Garantir que os cards ocupem toda a largura da coluna */
    height: 400px; /* Ajustar altura conforme necessário */
    background: var(--bg-color);
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    margin-left: 30px;
}

.products_loja-card_img {
    width: 100%;
    height: 150px; /* Ajuste conforme necessário */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products_loja-card_img-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products_loja-card_img-img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Garante que a imagem cubra o contêiner */
}

.products_loja-card_title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--font-color);
}

.products_loja-card_subtitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color-sub);
}

.products_loja-card_divider {
    width: 100%;
    border: 1px solid var(--main-color);
    border-radius: 50px;
}

.products_loja-card_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.products_loja-card_price {
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color-sub);
}

.products_loja-card_price span {
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color-sub);
}

.products_loja-card_btn {
    height: 35px;
    background: var(--bg-color);
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 0 15px;
    transition: all 0.3s;
}

.products_loja-card_btn svg {
    width: 100%;
    height: 100%;
    fill: var(--main-color);
    transition: all 0.3s;
}

.products_loja-card_img:hover {
    transform: translateY(-3px);
}

.products_loja-card_btn:hover {
    border: 2px solid var(--main-focus);
}

.products_loja-card_btn:hover svg {
    fill: var(--main-focus);
}

.products_loja-card_btn:active {
    transform: translateY(3px);
}