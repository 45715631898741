/* Estilos Globais */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'Lexend', sans-serif;
}

html.no-scroll, body.no-scroll {
    overflow: hidden;
}

/* Contêiner Principal */
.app_blogDetail-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fade-in 0.5s ease forwards;
}

/* Título Principal */
.app_blogDetail-container-title {
    color: #173463;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

/* Categoria */
.app_blogDetail-container-category p {
    color: #44aab8;
    font-size: 1rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: center;
}

/* Conteúdo */
.app_blogDetail-container-content {
    color: #333;
    line-height: 1.6;
    width: 100%;
    max-width: 800px;
    margin: 1.25rem 0;
    padding: 0 1rem;
}

.app_blogDetail-container-content p {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.app_blogDetail-container-content a {
    color: #44aab8;
    text-decoration: none;
    transition: color 0.3s ease;
}

.app_blogDetail-container-content a:hover {
    color: #0056b3;
}

.app_blogDetail-container-content img,
.app_blogDetail-container-content video {
    max-width: 100%;
    margin-bottom: 0.75rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
}

.app_blogDetail-container-content img:hover,
.app_blogDetail-container-content video:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Figura */
.app_blogDetail-container-content figure {
    margin: 0;
    padding: 0;
    text-align: center;
}

.app_blogDetail-container-content figure img {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 8px;
}

.app_blogDetail-container-content figcaption {
    font-size: 0.875rem;
    color: #666;
    margin-top: 0.5rem;
}

/* Tamanhos das fontes para os títulos */
.app_blogDetail-container-content h1 {
    font-size: 2rem;
    color: #44aab8;
    margin-bottom: 1.25rem;
    text-align: center;
}

.app_blogDetail-container-content h2 {
    font-size: 1.5rem;
    color: #44aab8;
    margin-bottom: 1.25rem;
}

.app_blogDetail-container-content h3 {
    font-size: 1.25rem;
    color: #44aab8;
    margin-bottom: 1.25rem;
}

.app_blogDetail-container-content blockquote {
    font-style: italic;
    border-left: 5px solid #44aab8;
    padding-left: 1rem;
    margin: 1.25rem 0;
    font-size: 1rem;
}

/* Listas */
.app_blogDetail-container-content ul,
.app_blogDetail-container-content ol {
    margin: 1.25rem 0;
    padding-left: 1.5rem;
}

.app_blogDetail-container-content li {
    margin-bottom: 0.75rem;
    font-size: 1rem;
}

/* Estilos para o post do Instagram */
.app_blogDetail-container-content iframe {
    width: 100%;
    max-width: 100%;
    border: none;
    margin-bottom: 1.25rem;
}

.app_blogDetail-container-content iframe:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Animação de Fade-In */
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsividade */
@media (max-width: 992px) {
    .app_blogDetail-container-title {
        font-size: 2rem;
    }

    .app_blogDetail-container-content p,
    .app_blogDetail-container-content a,
    .app_blogDetail-container-content li {
        font-size: 0.875rem;
    }

    .app_blogDetail-container-content h1 {
        font-size: 1.75rem;
    }

    .app_blogDetail-container-content h2 {
        font-size: 1.25rem;
    }

    .app_blogDetail-container-content h3 {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .app_blogDetail-container-title {
        font-size: 1.5rem;
    }

    .app_blogDetail-container-content p,
    .app_blogDetail-container-content a,
    .app_blogDetail-container-content li {
        font-size: 0.875rem;
    }

    .app_blogDetail-container-content h1 {
        font-size: 1.5rem;
    }

    .app_blogDetail-container-content h2 {
        font-size: 1.2rem;
    }

    .app_blogDetail-container-content h3 {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .app_blogDetail-container-title {
        font-size: 1.25rem;
    }

    .app_blogDetail-container-content p,
    .app_blogDetail-container-content a,
    .app_blogDetail-container-content li {
        font-size: 0.8rem;
    }

    .app_blogDetail-container-content h1 {
        font-size: 1.25rem;
    }

    .app_blogDetail-container-content h2 {
        font-size: 1rem;
    }

    .app_blogDetail-container-content h3 {
        font-size: 0.875rem;
    }
}
