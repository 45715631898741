html{
  overflow-x: hidden; /* Ocultar a barra de rolagem horizontal */
}

.iubenda-link {
  color: #44aab8; /* Altere esta cor para a cor principal do seu site */
  text-decoration: none;
  font-size: 14px;
}

.iubenda-link:hover {
  color: #173463; /* Altere esta cor para a cor de hover desejada */
}

.btn-my{
  padding: 12px 20px; /* Espaçamento interno */
  text-align: center; /* Alinhamento do texto */
  text-decoration: none; /* Remove sublinhado do texto */
  display: inline-block; /* Exibição inline */
  font-size: 16px; /* Tamanho da fonte */
  font-weight: 700;
  margin: 4px 2px; /* Margens */
  cursor: pointer; /* Cursor de ponteiro ao passar sobre o botão */
  border-radius: 10px; /* Bordas arredondadas */
  transition-duration: 0.4s; /* Duração da transição */
}

.btn-add{
    background-color: #44aab8;
    color: #fcfbfd;
}

.btn-add:hover{
  background-color: #173463;
}