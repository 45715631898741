.app_login-container{
    padding: 0;
    margin: 0;
}

.app_login-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    min-height: 100vh;
}

.app_login-right h2{
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    margin-top: 90px;
    color: #44aab8;
    text-align: center;
    width: 100%;
}

.app_login-right p{
    font-family: 'Lexend', sans-serif;
    color: #44aab8;
    text-align: center;
    font-size: small;
}

.app_login-right p a{
    text-decoration: none;
    color: #44aab8;
    font-weight: bold;
}

.app_login-right p a:hover{
    color: #173463;
}

.app_login-form{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.app_login-input-box {
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 2px solid #44aab8;
    margin: 30px 0;
}

.app_login-input-box label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 1em;
    color: #44aab8;
    font-weight: 500;
    pointer-events: none;
    transition: .5s;
}

.app_login-input-box input:focus~label,
.app_login-input-box input:valid~label {
    top: -5px;
}

.app_login-input-box .icon {
    position: absolute;
    right: 8px;
    font-size: 1.2em;
    color: #44aab8;
    line-height: 57px;
}


.app_login-input-box input {
    width: 500px;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    color: #173463;
    font-weight: 600;
    padding: 0 35px 0 5px;
}

.app_login-button{
    display: flex;
    justify-content: center;
}

.app_login-btnlog {
    width: 70%;
    height: 30px;
    background-color: #44aab8;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    color: #fcfdfb;
    font-weight: 500;
    margin: 30px;
}

.app_login-btnlog:hover {
    background-color: #173463;
}

.app_login-logo{
    margin-top: 40px;
    text-align: center;
    margin-bottom: 50px;
}

.app_login-logo img{
    width: 150px;
    height: auto;
}

@media (max-width:576px){
    .app_login-input-box input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        font-size: 1em;
        color: #173463;
        font-weight: 600;
        padding: 0 35px 0 5px;
    }
}