/* HomePage.css */
html.no-scroll,
body.no-scroll {
  overflow: hidden;
}

.content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.content.loaded {
  opacity: 1;
}
